export default ({ theme, user }) => {
  return `
    display: flex;
    flex-direction: column;
    width: 200px;
    height: 320px;
    /* height: 275px; */
    margin: 20px;
    /* padding: 10px; */
    background-color: ${theme.neutralColor};
    box-shadow: 4px 1px 12px ${theme.borderColor};
    border-radius: 3px;

    @media(max-width: 430px) {
      flex-direction: row;
      width: 350px;
      height: 150px;
    }

    @media(max-width: 475px) {
      margin: 5px;
    }


    &:hover {
      transform: scale(1.01);
    }

    .store-card-container{
      display: flex;
      flex-direction: row;
      align-content: flex-start;
      flex-wrap: wrap;
      width: 100%;
      height: 100%;

      .store-banner-and-logo-image-container {
        width: 100%;
        position: relative;

        @media(max-width: 430px){
          width: 45%;
        }
  
        .store-banner-image-container {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          align-self: flex-start;
          flex-direction: column;
          height: 150px;
          overflow: hidden;
          border-radius: 3px 3px 0px 0px;
          
          overflow: hidden;
          
          img.store-banner-image {
            max-width: none; /* Remove any max-width constraint */
            max-height: none; /* Remove any max-height constraint */
          }

        }
  
        .store-logo-container {
          height: 80px;
          width: 80px;
          display: flex;
          align-items: center;
          justify-content: center;
          align-self: flex-start;
          margin: 0px 10px 10px 0;
          flex-direction: column;
          margin: 7px 10px 10px -10px;
          border: none;
          outline: none;
          background-color: transparent;
          cursor: pointer;
          position: absolute;
          top: 30px;
          left: 10px;
  
          img.store-logo {
            width: 100%;
            border-radius: 50%;
          }
  
          small.store-name {
            margin: 10px;
            /* display: none; */
          }
        }
      }

      .store-details-container {
        display: flex;
        align-items: center;
        justify-content: space-around;
        flex-direction: column;
        height: 160px;
        width: 100%;

        @media(max-width: 430px){
          width: 55%;
          height: 150px;
        }
  
        small.store-name {
          font-size: 11px;
          text-align: center;
          text-transform: uppercase;
          font-weight: bold;
        }
  
        .store-rate {
          text-align: center;
          max-width: 90px;
          display: flex;
          flex-direction: column;
          align-items: center;
        
          small.store-rate {
            font-size: 10px;
            text-align: center;
            max-width: 90px;
          }
        }

      }

    }

    .store-header {
      min-height:16px;
      margin-bottom: 2px;
    }

    .store-name-fav-container {
      display: flex;
      justify-content: center;
      align-items: center;
      /* padding: 0px 10px; */
      width: 100%;

      .store-name-container{
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        padding: 3px 0px;
        flex-direction: column;
        text-transform: uppercase;

        small.store-name {
          font-size: 11px;
          text-align: left;
          font-weight: bold;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          width: 125px;

        }

      }

      .fav-button {
        display:flex;
        justify-content: center;
        margin: 2px;
      }

    }


    .deals-container {
      display: flex;
      align-items: center;
      justify-content: center;

      span {
        font-size: 12px;

        @media(max-width: 450px){
          font-size: 10px;

          i {
            font-size: small;
          }
        }

      }

      i {
        color: ${theme.darkSecondaryColor};
        font-size: 16px;
      }
    }


      .actions-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: row;
        flex-wrap: wrap;
        width: 100%;
        align-self: center;

        .more-info-shop-now-container{
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-around;
          width: 100%;


          .more-info {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            background-color: transparent;
            border: solid 1px ${theme.borderColor};
            border-radius: 12.5px;
            outline: none;
            padding: 3px 6px;
            cursor: pointer;

            i {
              font-size: 18px;
              margin: 0 5px 0 0;
            }
          }
        

          button.shop-now {
            background-color: ${theme.darkSecondaryColor};
            color: ${theme.secondaryTextColor};
            border-radius: 3px;
            padding: 5px 10px;
            outline: none;
            border: none;
            text-transform: uppercase;
            cursor: pointer;
            border-radius: 11.5px;

            @media(max-width: 430px){
              margin: 0px;
            }


            &:hover {
              background-color: ${theme.lightSecondaryColor};
              transform: scale(1.01);
            }
          }
        }

      }

      .contribute-now-button-holder {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 90%;
        margin: 5px 0px 10px 10px;
        
      }

    }
  `;
}
