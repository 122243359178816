import React, { Component } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import PropTypes from "prop-types";
import styles from './index.css';

import Favorite from 'SharedComponents/store-favorite';
import StoreBoostsList from 'SharedComponents/store-boosts-list';
import ContributeNowButton from 'SharedComponents/contribute-now-button';

const StoreWrapper = styled.div`${styles}`;

const Store = ({ store, onShopNow, onSetSharedItem, setFavShops }) => {

  const user = useSelector(state => state.authentication.user);

  return (
    <StoreWrapper store={store} user={user}>
      <div className="store-card-container">
        <div className="store-banner-and-logo-image-container">
            <div className="store-banner-image-container">
              <img className="store-banner-image" src={store.retail_store_banner_url} alt={store.merchant_name} />
              <button className="store-logo-container" onClick={() => onShopNow(store, 'shop')}>
                <img className="store-logo" src={store.retail_store_logo_url} alt={store.merchant_name} />
                {/* <small className="store-name">{store.merchant_name}</small> */}
              </button>
            </div>
        </div>


{/*         <div className="store-header">
          {
            parseInt(store.deals_count) ? (
              <div className="deals-container">
                <i className="material-icons">star</i>
                <i className="material-icons">star</i>
                <span>&nbsp;&nbsp;{`${store.deals_count} ${parseInt(store.deals_count) === 1 ? 'offer' : 'offers'} available`}&nbsp;&nbsp;</span>
                <i className="material-icons">star</i>
                <i className="material-icons">star</i>
              </div>
            ) : null
          }
        </div> */}

        {/* <button type="button" onClick={() => onShopNow(store, 'store')} className="store-image-container"></button> */}



            <div className="store-details-container">
              <div className="store-name-fav-container">
                <div className="store-name-container">
                  <small className="store-name">{store.merchant_name}</small>
                </div>

                <div className="fav-button">
                  <Favorite 
                    favorite={store.favorite} 
                    setFavShops={setFavShops}
                    retailStoreId={store.retail_store_id}
                    />      
                </div>

              </div>
              <div className="store-rate">
                {/* {store.rate} */}
                <StoreBoostsList
                    retailStore={store}
                    retailStoreId={store.retail_store_id}
                  />
              </div>
            


              <div className="actions-container">
                <div className="more-info-shop-now-container">
                  <button className="more-info" onClick={() => onSetSharedItem(store, 'store')}>
                    <i className="material-icons">info</i>
                    { !store.terms_conditions 
                      ? <span>info</span>
                      : <span style={{ fontSize: "smaller" }}>T&Cs</span>
                    }
                  </button>
                  <button className="shop-now" onClick={() => onShopNow(store, 'store')}>Shop Now</button>
                </div>
              </div>
              {user?.roundups_visible ?
                <div className="contribute-now-button-holder">
                  <ContributeNowButton
                    largeButton={ false }
                    shopButton={true}
                    contributeAndShop={true}
                    onShopNow={onShopNow}
                    onShopNowType={'shop'}
                    storeDetails={store}
                  />
                </div>
              : null}
          </div>
        </div>
    </StoreWrapper>
  );
}

export default Store;
