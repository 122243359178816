import React, { useState } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import PropTypes from "prop-types";

import ToastNotify from 'SharedComponents/toast-notifications';

import styles from './index.css';

const FavoriteWrapper = styled.div`${styles}`;

const Favorite = ({ favorite, setFavShops, retailStoreId, user }) => {

    return (
      !favorite ? (     
        user ? (
        <FavoriteWrapper      
          onClick={() => setFavShops(retailStoreId,'userAddFavShop')}
        >
          <i className="material-icons" style={{ color: '#ff4569' }}>favorite_border</i>
        </FavoriteWrapper>
        ) : (
        <FavoriteWrapper      
          onClick={() => ToastNotify  (
            {
              message: "Login to add a fave",
              notificationType: "error",
              myPromise: null,
              loadingMessage: null,
              loadingErrorMessage: null
            })
          }
        >
          <i className="material-icons" style={{ color: '#ff4569' }}>favorite_border</i>
        </FavoriteWrapper>

        )


      ) : (
        <FavoriteWrapper
          onClick={() => setFavShops(retailStoreId,'userDeleteFavShop')}
        > 
          <i className="material-icons" style={{ color: '#ff4569' }}>favorite</i>
        </FavoriteWrapper>
      )
    );
}
export default connect((state) => ({user: state.authentication.user }))(Favorite);

