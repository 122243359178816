export default ({ theme, largeButton, shopButton, contributeAndShop }) => {
  return `
    display: flex;
    align-items: center;
    justify-content: center;

    .contribute-now-container {
      display: flex;
      align-items: center;
      justify-content: center;
      height: ${ shopButton ? '30px' : '50px'};
      position: relative;
      z-index: ${contributeAndShop ? 0 : 100};

      .pop-over-container {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      button.contribute-now-action-button {
        font-size: ${
          shopButton
          ? '10px'
          :  !largeButton 
            ? '14px' 
            : '18px'
        };
        background-color: ${contributeAndShop ? theme.darkSecondaryColor : theme.alternateAccentColor};
        color: ${contributeAndShop ? theme.secondaryTextColor : theme.darkSecondaryColor};
        border-radius: 25px 0px 0px 25px;
        align-self: center;
        outline: none;
        height: ${!shopButton ? '50px' : '30px'}; */
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: bold;
        text-transform: uppercase;
        padding: ${
          shopButton 
          ? '0 10px'
          : !largeButton 
            ? '0 20px'
            : '0 30px'
        };
        border: 0px;
        white-space: normal;
        max-width: ${!largeButton ? 'fit-content' : 'none'}; 
        width: ${!shopButton ? 'auto' : '80px'};


        &:hover {
          background-color: ${contributeAndShop ? theme.lightSecondaryColor : theme.alternateAccentColor};
          cursor: pointer;
          filter: ${contributeAndShop ? 'brightness(1)' : 'brightness(0.9)'};
        }

        &:disabled {
          background-color: ${theme.borderColor};
          cursor: not-allowed;
        }


      }

      .contribute-now-select-amount {
        z-index: 101;
        position: relative;
        display: flex !important;
        /* border-left: 1px solid rgb(217, 217, 217); */
        border-left: ${contributeAndShop ? '1px solid rgb(217, 217, 217);' : '2px solid #ffffff;'}
        height: 100% !important;
        align-items: center;
        justify-content: space-evenly;
        

        [role="button"] {
          display: flex;
          align-items: center;
          border: none !important;
          height: 100% !important;

        }

        .options{
          z-index: 102 !important; /* Adjust the value as needed */
          position: relative; /* Establish a stacking context */
          background-color: rgb(33, 33, 33) !important;
          position: relative;
          top: 5px;
          left: 15px;

          .option{
            z-index: 103 !important; /* Adjust the value as needed */
            position: relative; /* Establish a stacking context */
            /* background-color: rgb(33, 33, 33) !important; */
            /* color: ${theme.alternateAccentColor} !important; */
            background-color: ${contributeAndShop ? theme.darkSecondaryColor : theme.alternateAccentColor} !important;
            color: ${contributeAndShop ? theme.alternateAccentColor : theme.darkSecondaryColor}!important;
          }
        }

        .dropdown-menu {
          width: ${ !shopButton ? '100px !important' : '74px !important'};
          height: 100% !important;
          margin: 0px !important;
          font-size: ${ !shopButton? '18px' : '13px'};
          border-radius: 0px 25px 25px 0px;
          font-weight: bold;
          border: none !important;
          /* background-color: ${theme.darkSecondaryColor}; */
          /* box-shadow: rgb(33 33 33) 0px 0px 3px; */
          /* color: ${theme.alternateAccentColor}; */
          background-color: ${contributeAndShop ? theme.darkSecondaryColor : theme.alternateAccentColor};
          color: ${contributeAndShop ? theme.alternateAccentColor : theme.darkSecondaryColor};

          &:hover {
            background-color: ${contributeAndShop ? theme.lightSecondaryColor : theme.alternateAccentColor};
            cursor: pointer;
            filter: ${contributeAndShop ? 'brightness(1)' : 'brightness(0.9)'};
          }

        }
      }
    }

/*     i.material-icons {
      color: ${contributeAndShop ? theme.darkSecondaryColor : theme.alternateAccentColor};
      fill: ${contributeAndShop ? '#ffffff' : '#000000'};
    } */


    .contribute-notification {
      font-size: 28px;

      .checkbox-entry{
        display: flex;
        padding: 5px;
        margin-bottom: 5px;
        align-items: center;

        .input-checkbox{
          min-width: 30px;
          height: 30px;
          margin-right: 10px;

          :checked:after{
            color: black;
          }
        }

        .input-label{
          font-size: 14px;
        }

        .highlight-message {
          color: red !important;
        }
        
      }
    }
  

  `;
};
