import React, { useState } from 'react';
import styled from 'styled-components';
import Masonry from 'react-masonry-component';
import { connect } from 'react-redux';
import Store from 'SharedComponents/store-card';
import DropdownSelect from 'SharedComponents/dropdown-select';
import Pagination from 'SharedComponents/pagination';

import styles from './index.css.js';


const StoresWrapper = styled.div`${styles}`;
const sortingOptions = [{
  label: 'Alphabetical',
  value: 'alphabetical'
}, {
  label: 'Most Popular',
  value: 'popularity'
}];

const Stores = ({ items, searchTerm, selectedCategory, onShopNow, onSetSharedItem, setFavShops, user }) => {
  const [sorting, setSorting] = useState('popularity');
  const [pageItems, setPageItems] = useState([]);

  // CHECK: This might not be the way to do it
  if (sorting === 'alphabetical') {
    items.sort((a, b) => {
      if (a.merchant_name < b.merchant_name) {
        return -1;
      }

      if (a.merchant_name > b.merchant_name) {
        return 1
      }

      return 0;
    });
  } else {
    items.sort((a, b) => parseInt(b.clicks) - parseInt(a.clicks));
  }

  return (
    <StoresWrapper>
    {!user ? (
                  <div className="description">
                  <p className="header-text">Log in to see your faves</p>
              </div>
      ) : (
          <div className="header">
            <div className="description">
              <p className="header-text">Click through and purchase from these shops to Boost Your Super</p>
            </div>
            <div className="sorter">
              <div className="visible-items">
                {
                  selectedCategory ? (
                    <span className="matches">Showing favorite shops in <span style={{ fontWeight: 'bold' }}>{selectedCategory} </span>category</span>
                  ) : searchTerm && searchTerm.length ? (
                    <span className="matches">Showing your favorite shops matching <span className="matched-term">{searchTerm} </span>search term</span>
                  ) : (
                    <span className="matches">Showing <span style={{ fontWeight: 'bold' }}>all </span>your favorite shops</span>
                  )
                }
              </div>
              <DropdownSelect className="dropdown-menu" value={sorting} onChange={(sorting) => {
                  if (sorting.value !== sorting) {
                    if (sorting.value === 'alphabetical') {
                      items.sort((a, b) => {
                        if (a.merchant_name < b.merchant_name) {
                          return -1;
                        }

                        if (a.merchant_name > b.merchant_name) {
                          return 1
                        }

                        return 0;
                      });
                    } else {
                    items.sort((a, b) => parseInt(b.clicks) - parseInt(a.clicks));
                    }

                    setSorting(sorting.value);
                  }
                }}
                options={sortingOptions}
              />
            </div>
          </div>
      )
        }
          <div className="stores-container">
            <Masonry
              className="stores-masonry-container"
              options={{ 
                isFitWidth: true,
                transitionDuration: '0.7s',
                stagger: 100,
              }}
            >
                {
                  pageItems.map((store) => (
                    <Store 
                      store={store}
                      key={store.retail_store_id}
                      onShopNow={onShopNow}
                      onSetSharedItem={onSetSharedItem}
                      setFavShops={setFavShops}
                    />
                  ))
                }
            </Masonry>
          </div> 
          <Pagination pageSize={24} items={items} onChangePage={(visibleItems) => {
            setPageItems(visibleItems);
          }}
          sorting={sorting} />
    
      
    </StoresWrapper>
  );
}

export default connect((state) => ({user: state.authentication.user }))(Stores);
