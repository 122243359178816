import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Ably } from 'ably';
import PropTypes from 'prop-types';

import CONFIG from 'Config';
import log from 'Utils/logger';

import * as AuthenticationActions from 'Flux/authentication/actions';

import useFetchRoundUpsShopperData from 'SharedHooks/fetch-roundups-shopper-data';

const useBroadcastAbly = ({ 
  channelName,
}) => {
  //const { channelName } = useParams();
  const authentication = useSelector((state) => state.authentication);
  const dispatch = useDispatch();
  
  const [messages, setMessages] = useState([]);
  const [connected, setConnected] = useState(false);
  const [updatingShopperVisibility, setUpdatingShopperVisibility] = useState(false);

  const { fetchRoundUpsShopperData } = useFetchRoundUpsShopperData();
  console.log('*** ** fetchRoundUpsShopperData: TRIGGER FROM BROADCAST');
  //const { fetchRoundUpsShopperData } = useFetchRoundUpsShopperData();

  const isConnectingRef = useRef(false);

  useEffect(() => {

    let ably, channel;

    async function initializeAbly() {
      if (channelName && authentication.user && !isConnectingRef.current) {
        isConnectingRef.current = true;
        const Ably = await import('ably'); // Dynamically import Ably
        const apiKey = CONFIG.ablyBroadcastApiKey;
        ably = new Ably.Realtime(apiKey);
        channel = ably.channels.get(channelName);
      
        const handleConnect = () => {
          console.log('Ably channel connected : ', channelName);
          log({
            event: "System-Notification",
            notification: `ably channel connected: ${channelName}`,
            source: "ably connection",
            error: null,
          });
          setConnected(true);
          isConnectingRef.current = false;
        };
      
        

        const handleError = (error) => {
          console.error('Ably connection error:', error);
          log({
            event: "System-Error",
            source: "Ably",
            'shopper_id': authentication? authentication.user.shopper_id : 'Not Signed In',
            info: JSON.stringify(error),
            error: error.message,
          });
          isConnectingRef.current = false;
        };

        async function updateShopperRoundupsVisibility(){
          setUpdatingShopperVisibility(true);
          console.log('updateShopperData triggered in Ably');
      
          try {
            const result = await dispatch(AuthenticationActions.fetchShopper());
      
            console.log('fetchShopper result in Ably is ', result);
      
            if (!result.data.status) {
              log({
                shopper_id: authentication? authentication.user.shopper_id : '000',
                event: "System-Error",
                source: "updateShopperRoundupsVisibility",
                error: result.data.error,
              });

            } else {

              const { roundups_visible, contribute_now_visible } = result.data.user_profile[0];
              dispatch(AuthenticationActions.updateRoundupsVisibility(roundups_visible));
              dispatch(AuthenticationActions.updateContributeNowVisibility(contribute_now_visible));
              //AuthenticationActions.setUser(result.data);
              //console.log("fetchShopper result : ", result.data.partner);
              }
          } catch (exception) {
            log({
              shopper_id: authentication? authentication.user.shopper_id : '000',
              event: "System-Error",
              source: "updateShopperRoundupsVisibility2",
              error: exception,
            });

          } finally {
            setUpdatingShopperVisibility(false);
          }
        }

        // Define the event handler for incoming messages
        const handleIncomingMessage = (message) => {
          console.log('broadcast message on ably', message);
          console.log('broadcast message.name on ably', message.name);
          console.log('broadcast message.data on ably', message.data);
          log({
            event: "System-Notification",
            notification: `ably brodcast incoming message stringified: ${stringify(message)}`,
            source: "ably handleIncomingMessage",
            error: null,
          });
          log({
            event: "System-Notification",
            notification: `ably brodcast incoming message.name: ${message.name}`,
            source: "ably handleIncomingMessage",
            error: null,
          });
          log({
            event: "System-Notification",
            notification: `ably brodcast incoming message.data: ${message.data}`,
            source: "ably handleIncomingMessage",
            error: null,
          });
          //console.log('broadcast message.data.data.name on ably', message.data.data.name);
          setMessages((prevMessages) => [...prevMessages, message.name]);

          // if (message.name === 'roundup-profile') {
          //   //we can consolidate this into one response in next release
          //   //roundup-profile is not being sent by BE anymore
          //   console.log('roundUpsObjectUpdated message received on ably. Triggering fetchRoundUpsShopperData');
          //   fetchRoundUpsShopperData();
          // } else if (message.data.message === 'bank-connected-retrieving-accounts') {
          //   console.log('bank-connected-retrieving-accounts message received on ably. Triggering setRetrievingAccounts to true');
          //   fetchRoundUpsShopperData();
          //   //setRetrievingAccounts(true);
          // } else if (message.data.message === 'consent-complete-accounts-synced') {
          //   console.log('consent-complete-accounts-synced message received on ably. Triggering setRetrievingAccounts to false');
          //   fetchRoundUpsShopperData();
          //   //setRetrievingAccounts(false);
          // } else if (message.data.message === 'consent-ready-accounts-deleted') {
          //   console.log('consent-ready-accounts-deleted message received on ably. Triggering fetchRoundUpsShopperData');
          //   fetchRoundUpsShopperData();
          //   //setDeletingBankConnection(false);
          // } else if (message.data.message === 'bank-deleted') {
          //   console.log('bank-deleted message received on ably. Triggering fetchRoundUpsShopperData');
          //   fetchRoundUpsShopperData();
          //   //setDeletingBankConnection(false);
          // } else if (message.data.message === 'bank-deleting') {
          //   console.log('bank-deleting message received on ably. Triggering fetchRoundUpsShopperData');
          //   fetchRoundUpsShopperData();
            //setDeletingBankConnection(false);
          if (message.data.message === 'update-shopper') {
            console.log('ably brodcast update-shopper message received on ably. Triggering updateShopperData');
            log({
              event: "System-Notification",
              notification: `ably brodcast update-shopper visibility: ${message.data.message}`,
              source: "ably handleIncomingMessage",
              error: null,
            });
            updateShopperRoundupsVisibility();
          } else if (message.name === 'roundup-message') {
            console.log('ably broadcast roundup-message. Triggering fetchRoundUpsShopperData');
            console.log('*** ** fetchRoundUpsShopperData: TRIGGER FROM BROADCAST - INCOMING MESSAGE');
            log({
              event: "System-Notification",
              notification: `ably broadcast roundup-message: ${message.data.message}`,
              source: "ably handleIncomingMessage",
              error: null,
            });
            fetchRoundUpsShopperData();
          }
        };

        // Subscribe to the channel with the event handlers
        channel.subscribe(handleIncomingMessage);
        channel.once('attached', handleConnect); // Attach event is triggered when the channel is successfully attached
        channel.on('error', handleError);

        // Clean up the subscription when the component is unmounted
        return () => {
          if (channel) {
            channel.unsubscribe(handleIncomingMessage);
            channel.off('attached', handleConnect);
            channel.off('error', handleError);
          }
          if (ably) {
            ably.close();
            console.log('Ably connection closed on unmount or sign-out');
            log({
              event: "System-Notification",
              notification: "ably broadcast channel closed due to unmount or sign-out",
              source: "ably useBroadcastAbly cleanup",
              error: null,
            });
          }
          isConnectingRef.current = false; 
        };
        
      } else if (isConnectingRef.current) {
        console.log('Ably connection blocked - is already in progress');
        log({
          event: "System-Notification",
          notification: "ably broadcast connection blocked - is already in progress",
          source: "ably useBroadcastAbly",
          error: null,
        });
      }
    }
    
     initializeAbly();
    
  }, [channelName, messages, connected, authentication.user]); // Re-run the effect when the channelName changes

  //return null;
};

useBroadcastAbly.propTypes = {
  channelName: PropTypes.string.isRequired,
  //fetchRoundUpsShopperData: PropTypes.func.isRequired,
};

export default useBroadcastAbly;
